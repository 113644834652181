import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SEO } from '../components/seo'

const PageNotFound = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="pt-8 pb-40">
        <h1 className="text-center">Page Not Found</h1>
        <p>
          Sorry, we couldn't find anything at <i>{location.href}</i>.
        </p>
      </div>
    </Layout>
  )
}

export default PageNotFound

export const Head = () => (
  <SEO title="Page Not Found (404)" />
)

